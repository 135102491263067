<template>
  <v-row class="profile">
    <v-col cols="12" class="pa-0">
      <div class="title">
        <div class="white--text text-uppercase text-sm-h5 text-h6 font-weight-bold">Profile setting</div>
      </div>
      <v-card
        v-if="load"
        tile
        elevation="5"
        max-width="1070"
        height="70vh"
        min-height="250"
        class="mx-lg-auto mx-4 mx-sm-15 pa-3 d-flex align-center justify-center flex-column"
      >
        <v-img max-height="80" contain src="@/assets/icon/loader.gif"></v-img>
        <div class="text-body-2 opasity--text">Loading...</div>
      </v-card>
      <div v-else>
        <v-card elevation="5" max-width="1070" class="mx-lg-auto mx-4 mx-md-15 pa-sm-10 px-4 py-5">
          <div class="d-flex flex-wrap justify-space-between mb-sm-0 mb-3">
            <div class="text-uppercase text-h5 font-weight-bold">{{ data.first_name }} {{ data.last_name }}</div>
            <div class="f18 role">{{ data.role.title }}</div>
          </div>
          <div class="px-sm-10 py-sm-5">
            <v-row>
              <v-col cols="12" md="6">
                <div class="mx-auto" style="max-width: 256px">
                  <div v-if="data.photo === null && !image">
                    <v-img @click="changeAvatar()" width="256" src="@/assets/img/ava.svg" class="link"></v-img>
                  </div>
                  <div v-else>
                    <v-img @click="changeAvatar()" width="256" height="216" cover :src="image || data.photo" class="link"></v-img>
                  </div>
                  <div class="mt-4 d-flex flex-wrap justify-space-between">
                    <v-btn :disabled="!data.photo" class="rounded" width="48" height="48" icon tile elevation="0" @click="deletePhote">
                      <v-icon color="error">mdi-delete</v-icon>
                    </v-btn>
                    <v-btn elevation="0" color="secondary" max-width="185" width="100%" height="48" @click="updatePhote" :disabled="!file">
                      Update
                    </v-btn>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" md="6">
                <div>
                  <div class="opasity--text">First name</div>
                  <v-text-field
                    v-model="data.first_name"
                    :error-messages="firstnameErrors"
                    filled
                    outlined
                    dense
                    required
                    color="input"
                    maxlength="50"
                  ></v-text-field>
                </div>
                <div class="mt-n2">
                  <div class="opasity--text">Last name</div>
                  <v-text-field
                    v-model="data.last_name"
                    :error-messages="lastnameErrors"
                    filled
                    outlined
                    dense
                    required
                    color="input"
                    maxlength="50"
                  ></v-text-field>
                </div>
                <div class="mt-n2">
                  <div class="opasity--text">Email</div>
                  <v-text-field
                    v-model="data.email"
                    :error-messages="emailErrors"
                    filled
                    outlined
                    dense
                    required
                    color="input"
                  ></v-text-field>
                </div>
                <div v-if="!emailErrors.length" class="d-flex mt-n6">
                  <div v-if="data.is_email_confirmed" class="success--text">
                    Confirmed {{ new Date(data.email_confirmed_at).toLocaleString() }}
                  </div>
                  <div v-else>
                    <div class="error--text">Your email is not confirmed yet. Please check your email Inbox.</div>
                    <div class="input--text link mt-3" @click="sendEmail">Re-sent confirmation link</div>
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-divider class="my-10"></v-divider>
            <div class="text-sm-right text-center">
              <v-btn elevation="0" width="180" height="48" color="primary" @click="update">Save</v-btn>
            </div>
          </div>
        </v-card>
        <v-card elevation="5" max-width="1070" class="mx-lg-auto mx-4 mx-md-15 pa-sm-10 px-4 py-5 mt-5">
          <div class="text-uppercase text-h5 font-weight-bold">Password</div>
          <div class="px-sm-10 py-sm-5">
            <v-row>
              <v-col cols="0" md="6">
                <div
                  v-html="'Please use at least one uppercase letter, one digit, and one of the allowed symbols: ~!@#$%^&*()_+-=|:<>?,.'"
                ></div>
              </v-col>
              <v-col cols="12" md="6">
                <div>
                  <div class="opasity--text">Current password</div>
                  <v-text-field
                    v-model="pass.currentPassword"
                    :error-messages="currentPasswordErrors"
                    :type="show ? 'text' : 'password'"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="show = !show"
                    filled
                    outlined
                    dense
                    required
                    class="pass-field"
                    color="input"
                  ></v-text-field>
                </div>
                <div class="mt-n2">
                  <div class="opasity--text">New password</div>
                  <v-text-field
                    v-model="pass.newPas"
                    :error-messages="newPasErrors"
                    :type="showNew ? 'text' : 'password'"
                    :append-icon="showNew ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="showNew = !showNew"
                    filled
                    outlined
                    dense
                    required
                    color="input"
                  ></v-text-field>
                </div>
              </v-col>
            </v-row>
            <v-divider class="mb-10 mt-5"></v-divider>
            <div class="text-sm-right text-center">
              <v-btn elevation="0" width="180" height="48" color="primary" @click="newPassword">Save</v-btn>
            </div>
          </div>
        </v-card>
        <v-card elevation="5" max-width="1070" class="mx-lg-auto mx-4 mx-md-15 pa-sm-10 px-4 py-5 mt-5">
          <div class="text-uppercase text-h5 font-weight-bold">Security</div>
          <div class="px-sm-10 py-sm-5">
            <v-row>
              <v-col cols="12" md="8">
                <div class="text-h6 text-md-left text-center">Two factor authentication is not enabled yet</div>
                <div class="opasity--text text-md-left text-center mt-3">
                  Two-factor authentication adds an additional layer of security to your account by requiring more than just a password to
                  log in.
                </div>
              </v-col>
              <v-col cols="12" md="4">
                <div class="text-md-right text-center">
                  <v-btn elevation="0" v-if="!data['2fa_enabled']" width="180" height="48" color="primary" @click="openEnable2FA">
                    Enable 2FA
                  </v-btn>
                  <v-btn elevation="0" v-else width="180" height="48" @click="disable">Disable 2FA</v-btn>
                </div>
              </v-col>
            </v-row>
            <v-row v-if="data['2fa_enabled']" class="mt-7">
              <v-col cols="12" md="8">
                <div class="d-flex justify-md-start justify-center">
                  <div class="text-h6">Recovery codes</div>
                  <div class="ml-5 available">{{ codes.unused_codes_left }} codes available</div>
                </div>
                <div class="text-md-left text-center opasity--text mt-3">
                  Recovery codes can be used to access your account in the event you lose access to your device and cannot receive
                  two-factor authentication codes
                </div>
              </v-col>
              <v-col cols="12" md="4">
                <div class="text-md-right text-center">
                  <v-btn elevation="0" width="180" height="48" color="secondary" @click="codeModal = !codeModal">View codes</v-btn>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </div>
      <v-dialog v-model="setupModal" max-width="820">
        <v-card>
          <v-progress-linear height="8" color="success" :value="progress"></v-progress-linear>
          <div class="pa-md-10 px-sm-5 py-sm-10 py-5 px-4">
            <div class="d-flex justify-space-between align-end">
              <div class="text-sm-h4 text-uppercase font-weight-bold text-h6">Setup 2fa</div>
              <div class="input--text">Step {{ progress / 50 }}/2</div>
            </div>
            <v-window touchless v-model="progress">
              <v-window-item :value="50">
                <div class="mt-sm-5 text-sm-h5 f18 font-weight-bold">Recovery Codes</div>
                <div class="text-sm-body-1 f14 opasity--text mt-3">
                  Treat your recovery codes with the same level of security as you would your password. We recommend saving them with a
                  password manager such as Lastpass, 1Password, or Keeper.
                </div>
                <v-row class="mt-10">
                  <v-col cols="6" sm="4" v-for="(item, i) in codes.codes" :key="item + i" class="text-center text-sm-h6 text-body-1 py-0">
                    {{ item }}
                  </v-col>
                </v-row>
                <div class="text-md-right text-center mt-5">
                  <v-btn elevation="0" outlined width="148" height="48" class="primary--text mr-sm-6" @click="copy">
                    <v-icon left>mdi-file-multiple</v-icon>
                    Copy
                  </v-btn>
                  <v-btn
                    elevation="0"
                    width="148"
                    height="48"
                    class="primary--text"
                    outlined
                    :href="`${url}/admin/api/v1/profile/2fa/otp/recovery-codes/download?apiKey=${token}`"
                  >
                    <v-icon left>mdi-folder-download</v-icon>
                    Download
                  </v-btn>
                </div>
                <div class="d-flex align-start mt-10">
                  <v-icon class="error--text mr-2">mdi-alert-octagon</v-icon>
                  <div class="error--text text-sm-body-1 f14">
                    If you lose access to your 2FA application/device and don’t have the recovery codes - you will lose access to your
                    account.
                  </div>
                </div>
              </v-window-item>
              <v-window-item :value="100">
                <div class="text-sm-body-1 f14 opasity--text mt-5">
                  Scan the QR code with your 2FA application/device. Most popular TOTP-based apps are supported: Google Authenticator,
                  1Password, etc.
                </div>
                <v-img class="mx-auto mt-5" width="212" :src="qr.qr_code"></v-img>
                <div class="opasity--text mt-5 text-center">Or enter this code to your 2FA application/device manually:</div>
                <v-text-field
                  v-model="qr.text_code"
                  filled
                  outlined
                  dense
                  color="input"
                  class="mx-sm-16 px-md-16 otp-input mt-5"
                ></v-text-field>
                <v-divider class="mb-5"></v-divider>
                <div class="opasity--text text-center mx-16">Enter the OTP from your 2FA application/device:</div>
                <div class="form-width mx-auto mt-2">
                  <v-form class="d-flex justify-center" ref="otp" lazy-validation @submit.prevent="enable">
                    <v-text-field
                      v-model="code"
                      class="otp-input mx-1"
                      :error-messages="codeErrors"
                      filled
                      outlined
                      dense
                      required
                      color="input"
                      maxlength="6"
                    ></v-text-field>
                  </v-form>
                </div>
              </v-window-item>
            </v-window>
            <v-divider class="my-5"></v-divider>
            <div class="d-flex justify-space-between">
              <v-btn elevation="0" width="100" height="48" text color="secondary" @click="setupModal = !setupModal">Close</v-btn>
              <v-btn elevation="0" v-if="progress != 100" width="184" height="48" color="secondary" @click="step2">Next</v-btn>
              <v-btn elevation="0" v-else width="184" height="48" color="primary" type="submit">Enable</v-btn>
            </div>
          </div>
        </v-card>
      </v-dialog>
      <v-dialog v-model="codeModal" max-width="820">
        <v-card class="pa-md-10 px-sm-5 py-sm-10 py-5 px-4">
          <div class="text-sm-h4 text-uppercase font-weight-bold text-h6">Recovery codes</div>
          <div class="text-sm-body-1 f14 opasity--text mt-3">
            Treat your recovery codes with the same level of attention as you would your password! We recommend saving them with a password
            manager such as Lastpass, 1Password, or Keeper.
          </div>
          <v-row class="mt-10">
            <v-col cols="6" sm="4" v-for="(item, i) in codes.codes" :key="item + i" class="text-center text-sm-h6 text-body-1 py-0">
              {{ item }}
            </v-col>
          </v-row>
          <div class="text-md-right text-center mt-5">
            <v-btn elevation="0" width="148" height="48" outlined class="primary--text mr-6" @click="copy">
              <v-icon left>mdi-file-multiple</v-icon>
              Copy
            </v-btn>
            <v-btn
              width="148"
              height="48"
              class="primary--text"
              outlined
              elevation="0"
              :href="`${url}/admin/api/v1/profile/2fa/otp/recovery-codes/download?apiKey=${token}`"
            >
              <v-icon left>mdi-folder-download</v-icon>
              Download
            </v-btn>
          </div>
          <div class="d-flex align-start mt-10">
            <v-icon class="error--text mr-2">mdi-alert-octagon</v-icon>
            <div class="error--text text-sm-body-1 f14">
              Put these in a safe spot. If you lose access to your 2FA application/device and don't have the recovery codes - you will lose
              access to your account.
            </div>
          </div>
          <v-divider class="my-5"></v-divider>
          <div class="d-flex justify-space-between">
            <v-btn elevation="0" width="100" height="48" text color="secondary" @click="codeModal = !codeModal">Close</v-btn>
            <v-btn elevation="0" width="300" height="48" color="secondary" @click="generate">Genetare new codes</v-btn>
          </div>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';

export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  validations: {
    data: {
      first_name: { required },
      last_name: { required },
      email: { required, email },
    },
    code: { required },
    pass: {
      currentPassword: { required },
      newPas: { required },
    },
  },
  data() {
    return {
      load: true,
      setupModal: false,
      codeModal: false,
      show: false,
      showNew: false,
      file: '',
      image: '',
      progress: 50,
      code: null,
      pass: {
        currentPassword: null,
        newPas: null,
      },
      url: window.origin,
      token: sessionStorage.getItem('jwt_token'),
      error: null,
    };
  },
  mounted() {
    this.$store.dispatch('getCodes').finally(() => {
      this.load = false;
    });
  },
  watch: {
    setupModal() {
      this.progress = 50;
      this.code = null;
      this.error = null;
      this.$v.code.$reset();
    },
  },
  computed: {
    data() {
      return this.$store.getters.profile;
    },
    codes() {
      return this.$store.getters.codes;
    },
    qr() {
      return this.$store.getters.qr;
    },
    firstnameErrors() {
      const errors = [];
      if (!this.$v.data.first_name.$dirty) {
        return errors;
      }
      !this.$v.data.first_name.required && errors.push('Please enter your first name');
      this.error == 'first_name__invalid' && errors.push('Provided first name is not valid');
      return errors;
    },
    lastnameErrors() {
      const errors = [];
      if (!this.$v.data.last_name.$dirty) {
        return errors;
      }
      !this.$v.data.last_name.required && errors.push('Please enter your last name');
      this.error == 'last_name__invalid' && errors.push('Provided last name is not valid');
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.data.email.$dirty) {
        return errors;
      }
      !this.$v.data.email.email && errors.push('Provided email address is not valid');
      !this.$v.data.email.required && errors.push('Please enter your email address');
      this.error == 'email__invalid' && errors.push('Provided email address is not valid');
      this.error == 'email__exists' && errors.push('Provided email address is already registered. Please use another one');
      return errors;
    },
    codeErrors() {
      const errors = [];
      if (!this.$v.code.$dirty) {
        return errors;
      }
      !this.$v.code.required && errors.push('Please enter OTP');
      this.error == 'code__invalid' && errors.push('Provided OTP is not valid. Please check and try again');
      this.error == 'code__wrong' && errors.push('Provided OTP is not correct. Please check and try again');
      this.error == 'otp__wrong' && errors.push('Provided OTP is not correct. Please check and try again');
      return errors;
    },
    currentPasswordErrors() {
      const errors = [];
      if (!this.$v.pass.currentPassword.$dirty) {
        return errors;
      }
      !this.$v.pass.currentPassword.required && errors.push('Please enter current password');
      this.error == 'credential__invalid' && errors.push('Current password is not correct. Please check and try again');
      return errors;
    },
    newPasErrors() {
      const errors = [];
      if (!this.$v.pass.newPas.$dirty) {
        return errors;
      }
      !this.$v.pass.newPas.required && errors.push('Please enter new password');
      this.error == 'new_password__invalid' &&
        errors.push('Please use at least one uppercase letter, one digit, and one of the allowed symbols: ~!@#$%^&*()_+-=|:<>?,.');
      return errors;
    },
  },
  methods: {
    getColorClass(block) {
      if (block == 'admin') {
        return 'color: #265A6E; background: #DDEDF3;';
      } else if (block == 'owner') {
        return 'color: #20743E; background: #DBF5E5;';
      } else if (block == 'user') {
        return 'color: #944D00; background: #FFEBD6;';
      }
    },
    notifi(btn) {
      if (btn == 'copy') {
        this.$notify({ message: 'Recovery codes were copied to your clipboard', horizontalAlign: 'center', verticalAlign: 'top' });
      } else if (btn == 'confirm') {
        this.$notify({ message: 'Email confirmation was sent successfully', horizontalAlign: 'center', verticalAlign: 'top' });
      } else if (btn == 'newPass') {
        this.$notify({ message: 'Password was successfully updated', horizontalAlign: 'center', verticalAlign: 'top' });
      } else if (btn == 'updatePhoto') {
        this.$notify({ message: 'Your profile picture was successfully updated', horizontalAlign: 'center', verticalAlign: 'top' });
      } else if (btn == 'update') {
        this.$notify({ message: 'Profile was successfully updated', horizontalAlign: 'center', verticalAlign: 'top' });
      } else if (btn == 'copyError') {
        this.$notify({ message: 'Sorry, error copied', type: 'error', horizontalAlign: 'center', verticalAlign: 'top' });
      } else if (btn == 'generate') {
        this.$notify({ message: 'New recovery codes. Old codes doesn’t work anymore.', horizontalAlign: 'center', verticalAlign: 'top' });
      } else if (this.error == 'confirmation__too_often') {
        this.$notify({
          message: 'Sorry, but email confirmation requests limit exceeded. Try again later',
          type: 'warning',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (this.error == 'email__already_confirmed') {
        this.$notify({ message: 'Email is already confirmed', type: 'warning', horizontalAlign: 'center', verticalAlign: 'top' });
      }
    },
    async update() {
      this.$v.data.$touch();
      if (!this.$v.data.$invalid) {
        this.error = null;
        const data = {
          first_name: this.data.first_name,
          last_name: this.data.last_name,
          email: this.data.email,
        };
        await this.$store
          .dispatch('updateProfile', data)
          .then(() => {
            this.notifi('update');
          })
          .catch((e) => {
            this.error = e.response.data.error[0];
          });
      }
    },
    changeAvatar() {
      const input = document.createElement('input');
      const self = this;
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*');
      input.addEventListener('change', function(e) {
        if (this.files && this.files[0]) {
          self.file = this.files[0];
          var reader = new FileReader();
          reader.onload = (e) => {
            self.image = e.target.result;
          };
          reader.readAsDataURL(this.files[0]);
        }
      });
      input.click();
    },
    async updatePhote() {
      await this.$store.dispatch('setPhoto', this.file).then(() => {
        this.file = '';
        this.notifi('updatePhoto');
      });
    },
    async deletePhote() {
      await this.$store.dispatch('deletePhoto').then(() => {
        this.image = '';
        this.$store.dispatch('getProfile');
      });
    },
    async sendEmail() {
      await this.$store
        .dispatch('sendEmail')
        .then(() => {
          this.notifi('confirm');
        })
        .catch((e) => {
          this.error = e.response.data.error[0];
          this.notifi();
        });
    },
    copy() {
      const string = this.codes.codes.join('\n');
      const container = document.querySelector('.v-dialog--active');
      this.$copyText(string, container)
        .then(() => {
          this.notifi('copy');
        })
        .catch(() => {
          this.notifi('copyError');
        });
    },
    async generate() {
      await this.$store.dispatch('generate').then(() => {
        this.notifi('generate');
      });
    },
    async step2() {
      await this.$store.dispatch('step2').then(() => {
        this.progress = 100;
      });
    },
    async newPassword() {
      this.$v.pass.$touch();
      if (!this.$v.pass.$invalid) {
        this.error = null;
        const data = {
          current_password: this.pass.currentPassword,
          new_password: this.pass.newPas,
        };
        await this.$store
          .dispatch('newPassword', data)
          .then(() => {
            this.notifi('newPass');
          })
          .catch((e) => {
            this.error = e.response.data.error[0];
            if (this.error == 'account__suspended') {
              this.$router.push('/account-blocked');
            }
          });
      }
    },
    async openEnable2FA() {
      await this.$store.dispatch('generate');
      this.setupModal = true;
    },
    async enable() {
      this.$v.code.$touch();
      if (!this.$v.code.$invalid) {
        await this.$store
          .dispatch('enable', this.code)
          .then(() => {
            this.setupModal = false;
          })
          .catch((e) => {
            this.error = e.response.data.error[0];
            this.notifi();
          });
      }
    },
    async disable() {
      await this.$store.dispatch('disable');
    },
  },
};
</script>

<style>
.profile .role {
  text-align: center;
  text-transform: capitalize;
  line-height: 42px;
  padding: 0 24px;
  height: 42px;
  border-radius: 24px;
  color: #0f5dbb;
  background: #b2defa;
}
.available {
  width: 155px;
  height: 38px;
  line-height: 38px;
  text-align: center;
  border-radius: 4px;
  color: rgba(101, 192, 6, 0.7);
  background: #e9fed2;
}
.form-width {
  max-width: 320px;
  width: 100%;
}
</style>
